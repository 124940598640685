import React from "react"
import { Box, Grid } from "@chakra-ui/core"
import SEO from "../components/SEO"
import PageBanner from "../components/PageBanner"
import PostItem from "../components/Blog/PostItem"
import RecentPosts from "../components/Blog/RecentPosts"
import Categories from "../components/Blog/Categories"
import Pagination from "../components/Blog/Pagination"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PostInterface } from "../types/post"
import { getPosts } from "../utils/post"

export const pageQuery = graphql`
  query BlogPage($skip: Int!, $limit: Int!) {
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          url
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      name
                    }
                  }
                }
              }
            }
            content {
              raw
            }
            date(formatString: "MMM D, YYYY")
            main_image {
              alt
              url
            }
            description {
              text
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data, pageContext }) => {
  const posts: PostInterface[] = getPosts(data)

  return (
    <Box>
      <SEO title="Blog" />
      <Layout>
        <PageBanner bannerText="வலைப்பதிவு" />
        <Grid
          mb={6}
          mt={12}
          maxW="1100px"
          mx="auto"
          px={3}
          templateColumns={["1fr", "1fr", "1fr", "1fr auto"]}
          gap={6}
        >
          <Box px={[2, null, 3, 4]}>
            {posts.map((post, index) => (
              <PostItem key={index} post={post} />
            ))}
            <Pagination pageContext={pageContext} />
          </Box>
          <Box>
            <RecentPosts />
            <Categories />
          </Box>
        </Grid>
      </Layout>
    </Box>
  )
}

export default Blog
