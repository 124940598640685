import React from "react"
import { PseudoBox, Box, Flex } from "@chakra-ui/core"
import BlogLink from "./BlogLink"

interface PaginationProps {
  pageContext: any
}

interface PageLinkProps {
  children: React.ReactNode
  to: string
}

const PageLink = (props: PageLinkProps) => {
  const { children, to } = props

  return (
    <BlogLink to={to}>
      <PseudoBox
        as="span"
        display="inline-block"
        mr={4}
        textDecoration="none"
        fontSize="lg"
        _hover={{ textDecoration: "underline" }}
      >
        {children}
      </PseudoBox>
    </BlogLink>
  )
}

const getPageLink = i => {
  if (i === 1) return "/blog"
  return `/blog/${i}`
}

const Pagination = (props: PaginationProps): JSX.Element => {
  const { pageContext } = props
  const {
    humanPageNumber,
    numberOfPages,
    previousPagePath,
    nextPagePath,
  } = pageContext

  let pages: JSX.Element[] = []

  for (let i = 1; i <= numberOfPages; i++) {
    pages.push(
      <PageLink key={i} to={getPageLink(i)}>
        <Box
          as="span"
          display="inline-block"
          color={i === humanPageNumber ? "#212121" : "#707070"}
        >
          {i}
        </Box>
      </PageLink>
    )
  }

  return (
    <Flex>
      {previousPagePath && <PageLink to={previousPagePath}>Prev</PageLink>}
      {pages}
      {nextPagePath && <PageLink to={nextPagePath}>Next</PageLink>}
    </Flex>
  )
}

export default Pagination
