import React from "react"
import { Box, Text, Flex, Grid, Heading, Image } from "@chakra-ui/core"
import { PostInterface } from "../../types/post"
import CategorySubLink from "./CategorySubLink"
import BlogLink from "./BlogLink"
import SharePost from "./SharePost"
import { getPostLink } from "../../utils/post"

interface PostItemProps {
  post: PostInterface
}

const PostItem = (props: PostItemProps): JSX.Element => {
  const { post } = props

  return (
    <Box mb={4}>
      <Text color="#707070">
        {post.categories.map((category, index) => (
          <CategorySubLink key={index} category={category} />
        ))}
      </Text>
      <BlogLink to={getPostLink(post)}>
        <Heading fontSize="2xl" mb={4}>
          {post.title}
        </Heading>
      </BlogLink>
      <Grid templateColumns={["1fr", "160px auto"]} gap={4}>
        <Box maxW="150px">
          <BlogLink to={getPostLink(post)}>
            <Box textAlign="center">
              <Image src={post.image.url + "&w=150"} alt={post.image.alt} />
            </Box>
          </BlogLink>
        </Box>
        <Box>
          <Text>{post.description}</Text>
          <Text mt={2} color="#707070">
            {post.date} - 4 min read
          </Text>
          <Flex mt={2} justify="flex-end">
            <SharePost post={post} />
          </Flex>
        </Box>
      </Grid>
    </Box>
  )
}

export default PostItem
